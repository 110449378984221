import ApexCharts from "apexcharts";
import Vue from 'vue/dist/vue.js';
import sal from 'sal.js';

if (document.querySelector('#fee-calculator')) {

    $(function() {

        let $range = $("#investmentRange")
        $range.on("input change", function() {
            vm.currentYear = parseInt(this.value)
        });

        // Faux display for Industry Internal Fee
        $("#totalYears").on("input", function() {
            // Update the faux display immediately without updating Vue
            let years = parseInt(this.value)
            if (!vm.isUpdating) vm.startUpdating();
            $('[data-percent-projected-years]').text(years);
        });

        // Debounced handler for updating Vue after a delay
        let totalYearsHandler = debounce(function() {
            let years = parseInt($("#totalYears").val());
            if (!isNaN(years)) {
                vm.totalYears = years;
                vm.currentYear = 1;
                resetToStart()
            }
        }, 300);

        $("#totalYears").on("change", function() {
            // Debounce and handle the Vue update
            totalYearsHandler();
        });

        // Faux display for Industry Internal Fee
        $("#industryImplementationFee").on("input", function() {
            // Update the faux display immediately without updating Vue
            let percent = parseFloat(this.value);
            if (!vm.isUpdating) vm.startUpdating();
            $('[data-percent-industry-implementation]').text(formatPercentage(percent));
        });

        // Debounced handler for updating Vue after a delay
        let industryImplementationFeeHandler = debounce(function() {
            let percent = parseFloat($("#industryImplementationFee").val());
            if (!isNaN(percent)) {
                vm.industry.implementationFeePercent = percent;
            }
        }, 300);

        $("#industryImplementationFee").on("change", function() {
            // Debounce and handle the Vue update
            industryImplementationFeeHandler();
        });

        // Faux display for Industry Internal Fee
        $("#industryInternal").on("input", function() {
            // Update the faux display immediately without updating Vue
            let percent = parseFloat(this.value);
            if (!vm.isUpdating) vm.startUpdating();
            $('[data-percent-industry-internal]').text(formatPercentage(percent));
        });

        // Debounced handler for updating Vue after a delay
        let industryInternalHandler = debounce(function() {
            let percent = parseFloat($("#industryInternal").val());
            if (!isNaN(percent)) {
                vm.industry.fee = percent;  // Vue will react to this change after debounce
            }
        }, 300);

        $("#industryInternal").on("change", function() {
            // Debounce and handle the Vue update
            industryInternalHandler();
        });

        // Faux display for Industry External Fee (Platform & Fund)
        $("#industryExternal").on("input", function() {
            // Update the faux display immediately without updating Vue
            let percent = parseFloat(this.value);
            if (!vm.isUpdating) vm.startUpdating();
            $('[data-percent-industry-external]').text(formatPercentage(percent));
        });

        // Debounced handler for updating Vue after a delay for Industry External
        let industryExternalHandler = debounce(function() {
            let percent = parseFloat($("#industryExternal").val());
            if (!isNaN(percent)) {
                // Assuming you want to distribute the change across both platform and fund fee:
                vm.industry.platformFeePercent = percent / 2;
                vm.industry.fundFeePercent = percent / 2;
            }
        }, 300);

        $("#industryExternal").on("change", function() {
            industryExternalHandler();
        });

        // Faux display for Projected Growth
        $("#projectedGrowth").on("input", function() {
            let percent = parseFloat(this.value);
            if (!vm.isUpdating) vm.startUpdating();
            $('[data-percent-projected-growth]').text(formatPercentage(percent));
        });

        let projectedGrowthHandler = debounce(function() {
            let percent = parseFloat($("#projectedGrowth").val());
            if (!isNaN(percent)) {
                vm.projectedGrowth = percent;  // Update Vue state after debounce
            }
        }, 300);

        $("#projectedGrowth").on("change", function() {
            projectedGrowthHandler();
        });

        let intervalCount = 0;
        let currentYearInternal = 1;
        let interval = null; // Declare interval variable globally
        let isPaused = true; // Track the state (paused or playing)
        let totalYearsReached = false; // Track whether we've reached the last year

        function checkOverflow() {
            const chartWrappers = document.querySelectorAll('[data-chart-wrapper]');
            const swipeContainer = document.querySelector('[data-swipe-container]');
            if (chartWrappers && swipeContainer) {

                let hasOverflow = Array.from(chartWrappers).some(wrapper => wrapper.scrollWidth > wrapper.clientWidth);
                
                if (hasOverflow) {
                    swipeContainer.classList.remove('is--hidden');
                } else {
                    swipeContainer.classList.add('is--hidden');
                }
            }
        }

        window.addEventListener('load', checkOverflow);
        window.addEventListener('resize', debounce(checkOverflow));

        // Function to update the current year and UI
        function updateYear() {
            vm.currentYear = currentYearInternal;
            $("#investmentRange").val(currentYearInternal);
            currentYearInternal += 1;
            intervalCount += 1;

            // If the currentYearInternal reaches totalYears, stop and show reset icon
            if (currentYearInternal > vm.totalYears) {
                clearInterval(interval);
                interval = null; // Ensure the interval is cleared
                totalYearsReached = true; // Set the flag
                showResetIcon(); // Hide play/pause and show reset icon
                togglePlayPauseText('reset'); // Change text to 'Reset'
                enableSlider(); // Ensure the slider is enabled when the end is reached
                return; // Stop execution here
            }
        }

        // Function to reset the variables (start from Year 1)
        function resetToStart() {
            currentYearInternal = 1;
            intervalCount = 0;
            totalYearsReached = false; // Reset the flag
            vm.currentYear = 1;
            $("#investmentRange").val(1);
            hideResetIcon(); // Show play icon and hide reset icon
            togglePlayPauseText('play'); // Reset text to 'Play'
            enableSlider(); // Re-enable the slider when reset
        }

        // Function to start the interval (play functionality)
        function startInterval() {
            // If it's at the end, reset to Year 1 and allow the user to start again
            if (totalYearsReached) {
                resetToStart(); // Reset everything if the last year has been reached
            }

            if (!interval) {
                updateYear(); // Call immediately to avoid the delay
                interval = setInterval(updateYear, 1000); // Set the interval for subsequent ticks
                isPaused = false;
                togglePlayPauseIcon(false); // Show pause icon
                togglePlayPauseText('pause'); // Change text to 'Pause'
                disableSlider(); // Disable slider when playing
            }
        }

        // Function to stop the interval (pause functionality)
        function stopInterval() {
            clearInterval(interval);
            interval = null;
            isPaused = true;
            togglePlayPauseIcon(true); // Show play icon
            togglePlayPauseText('play'); // Change text back to 'Play'
            enableSlider(); // Enable slider when paused
        }

        // Toggle play/pause icon visibility
        function togglePlayPauseIcon(isPlayVisible) {
            if (isPlayVisible) {
                $('[data-fee-play-icon]').removeClass('hidden');
                $('[data-fee-pause-icon]').addClass('hidden');
            } else {
                $('[data-fee-play-icon]').addClass('hidden');
                $('[data-fee-pause-icon]').removeClass('hidden');
            }
        }

        // Show reset icon and hide play/pause icons
        function showResetIcon() {
            $('[data-fee-play-icon]').addClass('hidden'); // Hide play icon
            $('[data-fee-pause-icon]').addClass('hidden'); // Hide pause icon
            $('[data-fee-reset-icon]').removeClass('hidden'); // Show reset icon
        }

        // Hide reset icon and show play icon
        function hideResetIcon() {
            $('[data-fee-reset-icon]').addClass('hidden'); // Hide reset icon
            $('[data-fee-play-icon]').removeClass('hidden'); // Show play icon
            $('[data-fee-pause-icon]').addClass('hidden'); // Ensure pause icon is hidden
        }

        // Toggle the play/pause/reset text
        function togglePlayPauseText(state) {
            const playTextElement = $('[data-fee-play-text]');

            // Update text based on the passed state: 'play', 'pause', 'reset'
            if (state === 'play') {
                playTextElement.text(playTextElement.data('fee-play-play-text')); // "Play"
            } else if (state === 'pause') {
                playTextElement.text(playTextElement.data('fee-play-pause-text')); // "Pause"
            } else if (state === 'reset') {
                playTextElement.text(playTextElement.data('fee-play-reset-text')); // "Reset"
            }
        }

        // Disable the slider while playing
        function disableSlider() {
            $('#investmentRange').prop('disabled', true);
        }

        // Enable the slider while paused or reset
        function enableSlider() {
            $('#investmentRange').prop('disabled', false);
        }

        // Event listener for the play/pause/reset button
        $('[data-fee-play]').on('click', function(e) {
            e.preventDefault(); // Prevent default anchor behavior
            if (totalYearsReached || vm.currentYear == vm.totalYears) {
                resetToStart(); // If reset is clicked, reset everything
            } else if (isPaused) {
                startInterval(); // If paused, start the interval (play)
            } else {
                stopInterval(); // If playing, stop the interval (pause)
            }
        });

        // Monitor manual changes to the year slider while paused
        $('#investmentRange').on('input change', function() {
            if (isPaused && !totalYearsReached) {
                currentYearInternal = parseInt($(this).val()); // Update currentYearInternal based on manual input
                vm.currentYear = currentYearInternal; // Reflect the manual change in the currentYear
            }
        });

    })

    let $calculatorBlock = $('#fee-calculator');

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    function formatLargeNumber(number, shorten = false) {
        const suffixes = ["", "K", "M", "B", "T", "Q"];
        let suffixIndex = 0;
        const isNegative = number < 0;
        const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);

        // Work with the absolute value of the number for formatting
        number = Math.abs(number);

        // If shorten is true and the user is on a mobile device, apply suffixes for large numbers
        if (shorten && isMobile) {
            while (number >= 1000 && suffixIndex < suffixes.length - 1) {
                number /= 1000;
                suffixIndex++;
            }
            return (isNegative ? "-" : "") + "Â£" + number.toFixed(1) + suffixes[suffixIndex];
        }

        // If shorten is false or not a mobile device, format it with commas
        return (isNegative ? "-" : "") + "Â£" + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }

    function formatPercentage(value) {
        if (isNaN(value)) {
            console.warn('Invalid value: not a number');
            return value;
        }
        value = Number(value);
        return value.toFixed(2) + '%';
    }

    function roundToTwo(num) {
        return +(Math.round(num + "e+2")  + "e-2");
    }

    Vue.component('investment-value', {
        props: ["value"],
        template: `<div>
            <input data-investment-value-input class="w-auto h-12 py-2 text-2xl text-center bg-transparent bg-white font-grotesk-bold ring-none hover:text-blue-900"
                   type="text"
                   v-on:input="onImmediateInput"
                   v-model="displayValue"
                   @blur="isInputActive = false"
                   @focus="isInputActive = true"/>
        </div>`,
        data: function() {
            return {
                isInputActive: false,
                internalValue: this.value
            }
        },
        methods: {
            onImmediateInput: function(event) {
                this.internalValue = event.target.value;
                vm.startUpdating(); // Show spinner
                this.debouncedUpdateValue(event.target.value);
            },
            updateValue: function(newValue) {
                var parsedValue = parseFloat(newValue.replace(/[^\d\.]/g, ""));
                if (isNaN(parsedValue) || parsedValue < 10000) {
                    parsedValue = 10000; // Set minimum value to 10000
                }
                this.$emit('input', parsedValue);
                // Ensure DOM updates are complete before resetting isUpdating
                this.$nextTick(() => {
                    //vm.isUpdating = false; // Hide spinner after update is done
                });
            }
        },
        computed: {
            displayValue: {
                get: function() {
                    if (this.isInputActive) {
                        return this.internalValue.toString();
                    } else {
                        return formatLargeNumber(this.internalValue);
                    }
                },
                set: function(modifiedValue) {
                    this.internalValue = modifiedValue;
                    this.$emit('input', parseFloat(modifiedValue.replace(/[^\d\.]/g, "")));
                }
            }
        },
        created() {
            // Create a debounced version of updateValue
            this.debouncedUpdateValue = debounce(function(value) {
                this.updateValue(value);
            }, 300);
        }
    });

    vm = new Vue({
        el: '#fee-calculator',
        delimiters: ['${', '}'],
        data: function() {
            return {
                investmentValue: investmentValue,
                projectedGrowth: projectedGrowth,
                currentYear: currentYear,
                totalYears: totalYears,
                wealthPlanFee: 0,
                wealthPlanEnabled: false,
                externalCostsCustom: false,
                isUpdating: false,
                isProcessing: false,
                updateTimeout: null,
                xentum: {
                    platformFeePercent: 0.20,
                    fundFeePercent: 0.26
                },
                industry: {
                    fee: industryFee,
                    platformFeePercent: industryPlatformFee,
                    fundFeePercent: industryFundFee,
                    implementationFeePercent: industryImplementationFee
                }
            };
        },
        mounted: function() {
            this.buildGraphs();
        },
        computed: {
            xentumTier: function() {
                return this.getTierData(this.investmentValue);
            },
            // Xentum Management Fee based on the xentumTier
            xentumManagementFee() {
                return this.xentumTier.managementFee;  // Uses the computed xentumTier
            },
            // Xentum Implementation Fee based on the xentumTier
            xentumImplementationFee() {
                return this.xentumTier.implementationFee;  // Uses the computed xentumTier
            },
            // Xentum Platform Fee based on the investment value and platform fee percentage
            xentumPlatformFee() {
                return (this.investmentValue / 100) * this.xentum.platformFeePercent;  // Calculates dynamically
            },
            // Xentum Fund Fee based on the investment value and fund fee percentage
            xentumFundFee() {
                return (this.investmentValue / 100) * this.xentum.fundFeePercent;  // Calculates dynamically
            },
            xentumFiscalDifference() {
                return this.investmentData.xentum.value[this.currentYear-1] - this.investmentData.industry.value[this.currentYear-1]
            },
            industryFiscalDifference() {
                return this.investmentData.industry.value[this.currentYear-1] - this.investmentData.xentum.value[this.currentYear-1]
            },
            // Industry Management Fee based on the investment value and industry fee percentage
            industryManagementFee() {
                return (this.investmentValue / 100) * this.industry.fee;
            },
            // Industry Platform Fee based on the investment value and platform fee percentage
            industryPlatformFee() {
                return (this.investmentValue / 100) * this.industry.platformFeePercent;
            },
            // Industry Fund Fee based on the investment value and platform fee percentage
            industryFundFee() {
                return (this.investmentValue / 100) * this.industry.fundFeePercent;
            },
            // Industry Implementation Fee based on the investment value and implementation fee percentage
            industryImplementationFee() {
                return (this.investmentValue / 100) * this.industry.implementationFeePercent;
            },
            investmentData: function() {
                this.isProcessing = true
                console.log('%c Investment Data recalculated ', 'background: #ff6542; color: #ffffff');
                let xentum_investment_value = this.investmentValue,
                    industry_investment_value = this.investmentValue,
                    xentum_investment_value_pre = this.investmentValue,
                    industry_investment_value_pre = this.investmentValue,
                    xentum_data_post = [],
                    xentum_fees_data = [],
                    industry_data_post = [],
                    industry_fees_data = [],
                    xentum_fees_internal_data = [],
                    xentum_fees_external_data = [],
                    xentum_investment_post = [],
                    xentum_fiscal_data = [],
                    industry_fiscal_data = [],
                    industry_investment_post = [],
                    industry_fees_internal_data = [],
                    logging = [],
                    industry_fees_external_data = [];

                    for (let i = 0; i < this.totalYears; i++) {

                        // Get the investment growth each year
                        let xentum_growth = xentum_investment_value * (this.projectedGrowth / 100);
                        let industry_growth = industry_investment_value * (this.projectedGrowth / 100);

                        // Apply growth before calculating fees
                        xentum_investment_value += xentum_growth;
                        industry_investment_value += industry_growth;

                        let xentum_investment_value_pre = xentum_investment_value;  // Pre-fee investment value
                        let industry_investment_value_pre = industry_investment_value;  // Pre-fee investment value

                        // Xentum - Internal fees total
                        let xentum_fees_total = (i === 0)
                            ? this.xentumImplementationFee + this.xentumManagementFee + this.xentumFundFee + this.xentumPlatformFee
                            : this.xentumManagementFee + (xentum_investment_value_pre * (this.xentum.fundFeePercent + this.xentum.platformFeePercent) / 100);

                        // Xentum - Internal fees total (no external fees included)
                        let xentum_internal_total = (i === 0)
                            ? this.xentumImplementationFee + this.xentumManagementFee
                            : this.xentumManagementFee;

                        // Industry - Internal fees total
                        let industry_fees_total = (i === 0)
                            ? (this.industryImplementationFee + this.industryManagementFee + this.industryPlatformFee + this.industryFundFee)
                            : (industry_investment_value_pre * (this.industry.fee + this.industry.platformFeePercent + this.industry.fundFeePercent) / 100);

                        // Calc Growth minus fees
                        let xentum_investment_post = xentum_investment_value_pre - xentum_fees_total;
                        let industry_investment_post = industry_investment_value_pre - industry_fees_total;

                        // Update investment values after fees
                        xentum_investment_value = xentum_investment_post;
                        industry_investment_value = industry_investment_post;

                        // Industry - Internal fees
                        let industry_internal_total = (i === 0)
                            ? this.industryImplementationFee + this.industryManagementFee
                            : (industry_investment_value_pre * (this.industry.fee / 100));

                        // Industry - External fees total
                        let industry_external_total = (i === 0)
                            ? (this.investmentValue * (this.industry.fundFeePercent + this.industry.platformFeePercent) / 100)
                            : (industry_investment_value_pre * (this.industry.fundFeePercent + this.industry.platformFeePercent) / 100);

                        // Xentum - External fees total
                        let xentum_external_total = (i === 0)
                            ? (this.investmentValue * (this.xentum.fundFeePercent + this.xentum.platformFeePercent) / 100)
                            : (xentum_investment_value_pre * (this.xentum.fundFeePercent + this.xentum.platformFeePercent) / 100);

                        let xentum_investment_fiscal_diff = (i == 1)
                            ? this.investmentValue - xentum_investment_post
                            : xentum_investment_value - xentum_investment_post;

                        let industry_investment_fiscal_diff = (i == 1)
                            ? this.investmentValue - industry_investment_post
                            : industry_investment_value - industry_investment_post;

                        let year = i+1;
                        logging.push({
                            Year: year,
                            // Xentum
                            XentumInvestmentPreFees: formatLargeNumber(xentum_investment_value_pre),
                            XentumManagementFee: formatLargeNumber(xentum_internal_total),
                            XentumExternalFees: formatLargeNumber(xentum_external_total),
                            XentumFeesTotal: formatLargeNumber(xentum_fees_total),
                            XentumInvestmentPostFees: formatLargeNumber(xentum_investment_post),
                            // Industry
                            IndustryInvestmentPreFees: formatLargeNumber(industry_investment_value_pre),
                            IndustryManagementFee: formatLargeNumber(industry_internal_total),
                            IndustryExternalFee: formatLargeNumber(industry_external_total),
                            IndustryInvestmentPostFees: formatLargeNumber(industry_investment_post),
                            IndustryFeesTotal: formatLargeNumber(industry_fees_total)
                        })

                        // Internal fees each year
                        xentum_fees_internal_data.push(roundToTwo(parseFloat(xentum_internal_total)));
                        industry_fees_internal_data.push(roundToTwo(parseFloat(industry_internal_total)));

                        // External fees each year
                        xentum_fees_external_data.push(roundToTwo(parseFloat(xentum_external_total)));
                        industry_fees_external_data.push(roundToTwo(parseFloat(industry_external_total)));

                        // Total fees each year
                        xentum_fees_data.push(roundToTwo(parseFloat(xentum_fees_total)));
                        industry_fees_data.push(roundToTwo(parseFloat(industry_fees_total)));

                        // Investment value minus fees
                        xentum_data_post.push(roundToTwo(parseFloat(xentum_investment_post)));
                        industry_data_post.push(roundToTwo(parseFloat(industry_investment_post)));

                        // Fiscal difference each year
                        xentum_fiscal_data.push(roundToTwo(parseFloat(xentum_investment_fiscal_diff)));
                        industry_fiscal_data.push(roundToTwo(parseFloat(industry_investment_fiscal_diff)));

                    }

                    console.table(logging)

                this.xentum.fiscalTotal = xentum_investment_post;
                this.industry.fiscalTotal = industry_investment_post;

                this.debouncedupdateGraphs();

                return {
                    xentum: {
                        fees: {
                            internal: xentum_fees_internal_data,
                            external: xentum_fees_external_data,
                            total: xentum_fees_data.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
                        },
                        value: xentum_data_post,
                        fiscalDiff: xentum_fiscal_data,
                    },
                    industry: {
                        fees: {
                            internal: industry_fees_internal_data,
                            external: industry_fees_external_data,
                            total: industry_fees_data.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
                        },
                        value: industry_data_post,
                        fiscalDiff: industry_fiscal_data,
                    },
                };
            },
        },
        methods: {
            // Method to monitor and reset 'isUpdating'
            monitorIsUpdating() {
                // Clear the previous timeout if it exists
                if (this.updateTimeout) {
                    clearTimeout(this.updateTimeout);
                }
                // Set a new timeout to reset 'isUpdating' after 2.5 seconds
                this.updateTimeout = setTimeout(() => {
                    this.isUpdating = false;
                }, 2500); // 2.5 seconds
            },
            // Method to be called whenever you want to update and monitor 'isUpdating'
            startUpdating() {
                this.isUpdating = true; // Set the updating flag to true
                this.monitorIsUpdating(); // Start the monitor process
            },
            updateGraphs() {
                console.log('%c Investment Graphs Updated ', 'background: #6695ff; color: #fff');
                const cachedInvestmentData = this.investmentData
                // Update the investment graph with the recalculated data and annotations
                graphInvestment.updateOptions({
                    chart: {
                        animations: {
                            enabled: false
                        }
                    },
                    title: {
                        text: formatLargeNumber(this.investmentValue) + ' investment over ' + this.totalYears + ' years',
                    },
                    series: [
                        { name: 'Xentum (fixed fee)', type: 'line', data: cachedInvestmentData.xentum.value, color: '#004FFF' },
                        { name: 'Industry (' + formatPercentage(this.industry.fee) + ' fee)', type: 'line', data: cachedInvestmentData.industry.value, color: '#333333' }
                    ],
                    annotations: {
                        yaxis: [
                            {
                                y: cachedInvestmentData.xentum.value[cachedInvestmentData.xentum.value.length - 1],
                                borderColor: '#004FFF',
                                label: {
                                    borderColor: '#004FFF',
                                    style: {
                                        fontSize: '14px',
                                        color: '#fff',
                                        background: '#004FFF'
                                    },
                                    text: formatLargeNumber(cachedInvestmentData.xentum.value[cachedInvestmentData.xentum.value.length - 1])
                                }
                            },
                            {
                                y: cachedInvestmentData.industry.value[cachedInvestmentData.industry.value.length - 1],
                                borderColor: '#333333',
                                label: {
                                    borderColor: '#333333',
                                    style: {
                                        color: '#fff',
                                        fontSize: '14px',
                                        background: '#333333'
                                    },
                                    text: formatLargeNumber(cachedInvestmentData.industry.value[cachedInvestmentData.industry.value.length - 1])
                                }
                            }
                        ]
                    },
                    xaxis: {
                        categories: Array.from({ length: this.totalYears }, (_, i) => `Year ${i + 1}`),
                    },
                })

                let cachedinvestmentFeesGraphData = [];
                for (let i = 0; i < this.totalYears; i++) {
                    // Store fee data
                    cachedinvestmentFeesGraphData.push({
                        xentumManagement: this.investmentData.xentum.fees.internal[i],
                        xentumExternal: this.investmentData.xentum.fees.external[i],
                        industryManagement: this.investmentData.industry.fees.internal[i],
                        industryExternal: this.investmentData.industry.fees.external[i],
                    });
                }

                // Prepare the updated series data for graphFees
                const xentumManagementData = cachedinvestmentFeesGraphData.map(f => f.xentumManagement);
                const xentumExternalData = cachedinvestmentFeesGraphData.map(f => f.xentumExternal);
                const industryManagementData = cachedinvestmentFeesGraphData.map(f => f.industryManagement);
                const industryExternalData = cachedinvestmentFeesGraphData.map(f => f.industryExternal);

                graphFees.updateOptions({
                    chart: {
                        animations: {
                            enabled: false
                        }
                    },
                    title: {
                        text: 'Compounded fees over ' + this.totalYears + ' years',
                    },
                    series: [
                        { name: 'Xentum (fixed fee)', group: 'Xentum', data: xentumManagementData, color: '#004FFF' },
                        { name: 'Xentum external (' + formatPercentage(this.xentum.fundFeePercent + this.xentum.platformFeePercent) + ')', group: 'Xentum', data: xentumExternalData, color: '#7DAAFF' },
                        { name: 'Industry (' + formatPercentage(this.industry.fee) + ')', group: 'Industry', data: industryManagementData, color: '#333333' },
                        { name: 'Industry external (' + formatPercentage(this.industry.fundFeePercent + this.industry.platformFeePercent) + ')', group: 'Industry', data: industryExternalData, color: '#898d98' }
                    ],
                    xaxis: {
                        categories: Array.from({ length: this.totalYears }, (_, i) => `Year ${i + 1}`),
                    },
                }).then(() => {
                    sal()
                    vm.isUpdating = false
                    vm.isProcessing = false
                })
            },
            getTierData(investmentValue) {
                if (investmentValue <= 250000) return xentumTierData[0];
                if (investmentValue > 250000 && investmentValue < 500000) return xentumTierData[1];
                if (investmentValue >= 500000 && investmentValue < 1500000) return xentumTierData[2];
                if (investmentValue >= 1500000 && investmentValue < 3000000) return xentumTierData[3];
                return xentumTierData[4];
            },
            buildGraphs: function() {
                let options_investment = {
                    title: {
                        text: formatLargeNumber(this.investmentValue) + ' investment over ' + totalYears + ' years',
                        align: 'center',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: true,
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#263238'
                        },
                    },
                    subtitle: {
                        text: 'xentum.co.uk',
                        align: 'right', // options: 'left', 'center', 'right'
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: true,
                        style: {
                            fontSize: '14px',
                            fontFamily: '"Neue Haas Grotesk Roman", Arial, sans-serif',
                            color: '#6a7899'
                        }
                    },
                    stroke: {
                        width: 3
                    },
                    chart: {
                        id: 'chart_value',
                        fontFamily: '"Neue Haas Grotesk Roman", Arial, sans-serif',
                        type: 'line',
                        height: 500,
                        toolbar: {
                            show: false // Disables the toolbar (three-bar menu)
                        },
                        zoom: {
                            enabled: false
                        },

                    },
                    series: [
                        {
                            name: 'Xentum (fixed fee)',
                            type: 'line',
                            color: '#004FFF',
                            data: this.investmentData.xentum.value
                        },
                        {
                            name: 'Industry (' + formatPercentage(this.industry.fee) + ' fee)',
                            type: 'line',
                            color: '#333333',
                            data: this.investmentData.industry.value
                        }
                    ],
                    xaxis: {
                        categories: Array.from({ length: this.totalYears }, (_, i) => `Year ${i + 1}`),
                        title: {
                            text: 'Years'
                        }
                    },
                    yaxis: [
                        {
                            title: {
                                text: 'Investment Value'
                            },
                            labels: {
                                formatter: function(value) {
                                    return formatLargeNumber(value, true);
                                }
                            }
                        }
                    ],
                    tooltip: {
                        y: {
                            formatter: function(value) {
                                return formatLargeNumber(value);
                            }
                        }
                    },
                };

                graphInvestment = new ApexCharts(document.querySelector("#chart_value"), options_investment);
                graphInvestment.render();

                let series = [];
                let xentumManagementData = [];
                let xentumExternalData = [];
                let industryManagementData = [];
                let industryExternalData = [];

                for (let i = 0; i < this.totalYears; i++) {
                    xentumManagementData.push(this.investmentData.xentum.fees.internal[i]);
                    xentumExternalData.push(this.investmentData.xentum.fees.external[i]);
                    industryManagementData.push(this.investmentData.industry.fees.internal[i]);
                    industryExternalData.push(this.investmentData.industry.fees.external[i]);
                }

                series.push(
                    {
                        name: 'Xentum (fixed fee)',
                        group: 'Xentum',
                        data: xentumManagementData,
                        color: '#004FFF'
                    },
                    {
                        name: 'Xentum external (' + formatPercentage(this.xentum.fundFeePercent + this.xentum.platformFeePercent) + ')',
                        group: 'Xentum',
                        data: xentumExternalData,
                        color: '#7DAAFF'
                    },
                    {
                        name: 'Industry (' + formatPercentage(this.industry.fee) + ')',
                        group: 'Industry',
                        data: industryManagementData,
                        color: '#333333'
                    },
                    {
                        name: 'Industry external (' + formatPercentage(this.industry.fundFeePercent + this.industry.platformFeePercent) + ')',
                        group: 'Industry',
                        data: industryExternalData,
                        color: '#898d98'
                    }
                );

                let options_fees = {
                    title: {
                        text: 'Compounded fees over ' + this.totalYears + ' years',
                        align: 'center',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: true,
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#263238'
                        },
                    },
                    subtitle: {
                        text: 'xentum.co.uk',
                        align: 'right', // options: 'left', 'center', 'right'
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: true,
                        style: {
                            fontSize: '14px',
                            fontFamily: '"Neue Haas Grotesk Roman", Arial, sans-serif',
                            color: '#6a7899'
                        }
                    },
                    chart: {
                        id: 'chart_fees',
                        fontFamily: '"Neue Haas Grotesk Roman", Arial, sans-serif',
                        type: 'bar',
                        height: 500,
                        stacked: true,
                        stackType: 'normal',
                        toolbar: {
                            show: false // Disables the toolbar (three-bar menu)
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    series: series,
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%',
                            endingShape: 'rounded',
                        },
                    },
                    xaxis: {
                        categories: Array.from({ length: this.totalYears }, (_, i) => `Year ${i + 1}`),
                        title: {
                            text: 'Years'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Fees'
                        },
                        labels: {
                            formatter: function(value) {
                                return formatLargeNumber(value, true);
                            }
                        }
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function(value) {
                                return formatLargeNumber(value);
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                        offsetX: 0
                    }
                };

                graphFees = new ApexCharts(document.querySelector("#chart_fees"), options_fees);
                graphFees.render();

            },
            calculateExternalFeePercent: function(value, percent) {;
                return value * (percent / 100);
            },
        },
        watch: {
            wealthPlanEnabled(enabled) {
                if (enabled) {
                    let selectedTier = this.getTierData(this.investmentValue);
                    this.wealthPlanFee = selectedTier.wealthPlanFee;
                } else {
                    this.wealthPlanFee = 0;
                }
            },
        },
        created() {
            // Create a debounced version of updateGraphs to use in the watcher
            this.debouncedupdateGraphs = debounce(this.updateGraphs, 500);
        },
        filters: {
            formatNumber: function(value) {
                return formatLargeNumber(value);
            },
            formatPercentage: function(value) {
                return formatPercentage(value)
            }
        }
    });
}
