// Glide
import Glide from '@glidejs/glide'

// Lity
import lity from 'lity'

// Tippy
import tippy from 'tippy.js';

// Match height
import MatchHeight from '@tannerhodges/match-height';

// Sal.
import sal from 'sal.js';

/**
 * Lazy Sizes
 *
 * @link https://github.com/aFarkas/lazysizes
 *
 */
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';


// jQuery
import jQuery from "jquery"
window.$ = window.jQuery = jQuery

// if you're using a bundler, first import:
import Headroom from "headroom.js";

$(function () {

    document.querySelectorAll('[data-mobile-sub-menu-toggle]').forEach(toggle => {
        toggle.addEventListener('click', function(event) {
            event.preventDefault()
            // Find the closest data-mobile-sub-menu in the parent of the clicked element
            const subMenu = this.closest('[data-mobile-sub-menu-toggle]').parentElement.querySelector('[data-mobile-sub-menu]');
            
            // Add 'hidden' class to all other data-mobile-sub-menu elements
            document.querySelectorAll('[data-mobile-sub-menu]').forEach(menu => {
                menu.classList.add('hidden');
            });
    
            // Remove 'font-grotesk-bold' from all toggles
            document.querySelectorAll('[data-mobile-sub-menu-toggle]').forEach(t => {
                t.classList.remove('font-grotesk-bold');
                t.classList.remove('text-blue-900');
            });
            
            // Remove 'hidden' class from the closest sub-menu
            subMenu.classList.remove('hidden');
            
            // Add 'font-grotesk-bold' class to the active toggle
            this.classList.add('font-grotesk-bold');
            this.classList.add('text-blue-900');
        });
    });
    

    /**
    *
    * Close / Open the mobile nav
    *
    */
    function toggleMobileNav() {
        $('[data-mobile-menu]').toggleClass('is--active')
        $('[data-hamburger] .close-button').toggleClass('hidden')
        $('[data-mobile-menu-alt]').toggleClass('is--active')
        $('[data-mobile-menu-child]').scrollTop(0);
        $('body')
            .toggleClass('overflow-hidden')
            .toggleClass('mobile-nav-open')
    }

    /**
    *
    * Toggle the hamburger
    *
    */
    let $hamburger = $('[data-hamburger]')
    $hamburger.on('click', function(e){
        $(this).toggleClass('is-active')
        e.preventDefault()
        toggleMobileNav()
    })
    
    let $closeMenu = $('[data-close-menu]')
    $closeMenu.on('click', function(e){
        $('[data-mobile-menu]').removeClass('is--active')
        $('[data-hamburger] .close-button').removeClass('hidden')
        $('[data-hamburger]').removeClass('is-active')
        $('[data-mobile-menu-alt]').removeClass('is--active')
        $('[data-mobile-menu-child]').scrollTop(0);
        $stack.removeClass('is--active')
        $('body')
            .removeClass('overflow-hidden')
            .removeClass('mobile-nav-open')
    })
    
    /**
    *
    * Toggle the child menus on mobile
    *
    */
    let $childToggle = $('[data-mobile-menu-child-toggle]')
    let currentChildDepth = 0
    $childToggle.on('click', function(e){
        e.preventDefault()
        let id = $(this).data('mobile-menu-child-toggle')
        currentChildDepth += 1
        console.log(currentChildDepth)
        $('[data-mobile-menu-child-back]').addClass('is--active')
        $('[data-mobile-menu-child]').scrollTop(0);
        $('[data-mobile-menu-child]').removeClass('is--active')
        $('[data-mobile-menu-child="'+id+'"]').addClass('is--active')
    })
    /**
    *
    * Toggle the child menus on mobile
    *
    */
    let $childToggleBack = $('[data-mobile-menu-child-back]')
    $childToggleBack.on('click', function(e){
        e.preventDefault()
        currentChildDepth -= 1
        $('[data-mobile-menu-child]').removeClass('is--active')
        if (currentChildDepth <= 0) {
            $('[data-mobile-menu-child-back]').removeClass('is--active')
        }
    })
    
    /**
    *
    * Close the hamburger
    *
    */
    let $menuClose = $('[data-hamburger-close]')
    $menuClose.on('click', function(e){
        e.preventDefault()
        toggleMobileNav()
    })
    
    /**
    *
    * Allow clicking outside of the mobile nav
    *
    */
    $('body').on('click', function(e) {
    
        if ( $(e.target).closest('.mobile-menu').length == 0 &&
             $(e.target).closest('[data-hamburger]').length == 0 &&
             $('body').hasClass('mobile-nav-open') ) {
                $hamburger.toggleClass('is-active')
                toggleMobileNav()
        }
    
    })
    

    sal()

    // grab an element
    var myElement = document.querySelector("header");
    // construct an instance of Headroom, passing the element
    var headroom  = new Headroom(myElement);
    // initialise
    headroom.init();
    
        // Smooth scroll function
        function smoothScroll(target) {
            var targetElement = document.querySelector(target);
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth"
                });
            }
        }
    
        // Check for hash links
        document.querySelectorAll('a[href^="#"]').forEach(function(link) {
            link.addEventListener("click", function(event) {
                event.preventDefault();
                var hash = this.getAttribute("href");
                if (hash && document.querySelector(hash)) {
                    headroom.pin(); // Pin Headroom before scroll
                    smoothScroll(hash);
                }
            });
        });

    $('.has--child').on('click', function (e) {

        // Second click for top level
        if ($(this).hasClass('click-disabled')) {
            e.preventDefault();
            $(this).removeClass('click-disabled')
        }

        $('.dropdown').removeClass('is-open')

        $(this).parent().find('.dropdown').addClass('is-open')

    });

    $('[data-sticky-dismiss]').on('click', function (e) {

        e.preventDefault();

        if ($(this).text() == 'Close') {
            $(this).text('Open')
        } else {
            $(this).text('Close')
        }

        $('[data-sticky-container]').toggleClass('is--dismissed');

        if ($('[data-sticky-container]').hasClass('is-dismissed')) {
            $('[data-sticky-container]').css('top', $('.header-wrapper').height() + $('[data-contact-callout]').height());
        }

    });

    $('body').on('click', function (e) {
        if ($(e.target).closest('.dropdown').length === 0 && !$(e.target).hasClass('has--child')) {
            $('.dropdown').removeClass('is-open')
        }
    });

    tippy('.tooltip', {
        arrow: true,
        animation: 'scale',
        placement: 'right',
    });

    $('.tooltip').on('click', function (e) {
        e.preventDefault();
    })

    $('[data-glide-graph]').on('click', function (e) {
        e.preventDefault();
        $('[data-glide-graph]').removeClass('is--active')
        $(this).addClass('is--active')
    })

    let bioModal = false;

    let glide = $('.glide')
    if (glide.length > 0) {
        var slide = new Glide('.glide', {
            type: 'slider',
            gap: 30,
            perView: 2,
            rewindDuration: 200,
            breakpoints: {
                1200: {
                    perView: 1
                }
            }
        })

        let $next = $('[data-glide-next]')
        let $prev = $('[data-glide-prev]')

        $next.on('click', (event) => {
            event.preventDefault()
            slide.go('>')
        })

        $prev.on('click', (event) => {
            event.preventDefault()
            slide.go('<')
        })

        slide.mount()

    }

    let carousel = $('.news-carousel')
    if (carousel.length > 0) {
        var slide = new Glide('.news-carousel', {
            type: 'carousel',
            gap: 30,
            perView: 3,
            rewindDuration: 200,
            autoplay: 8000,
            breakpoints: {
                1200: {
                    perView: 2
                },
                768: {
                    perView: 1
                }
            }
        })

        let $next = $('[data-glide-next]')
        let $prev = $('[data-glide-prev]')

        $next.on('click', (event) => {
            event.preventDefault()
            slide.go('>')
        })

        $prev.on('click', (event) => {
            event.preventDefault()
            slide.go('<')
        })

        slide.mount()

    }

    if (document.querySelector('[data-swipe-container]')) {
        document.querySelector('[data-swipe-container]').addEventListener('click', function() {
            this.style.display = 'none';
        });
        document.querySelector('[data-swipe-container]').addEventListener('touchstart', function() {
            this.style.display = 'none';
        });
    }

    let graphs = $('.graphs');
    if (graphs.length > 0) {
        graphs.each(function(index, graph) {
            var glideInstance = new Glide(graph, {
                type: 'slider',
                gap: 0,
                perView: 1,
                autoplay: false,
                swipeThreshold: false,
                dragThreshold: false
            });

            let $next = $('[data-glide-graph="0"]');
            let $prev = $('[data-glide-graph="1"]');

            $next.on('click', function(event) {
                event.preventDefault();
                $('[data-swipe-container]').hide()
                glideInstance.go('=0');
            });

            $prev.on('click', function(event) {
                event.preventDefault();
                $('[data-swipe-container]').hide()
                glideInstance.go('=1');
            });

            glideInstance.on('run', function() {
                var currentIndex = glideInstance.index;

                // Select all elements with the data attribute
                var allGraphElements = document.querySelectorAll('[data-glide-graph]');

                // Remove 'is--active' class from all elements
                allGraphElements.forEach(function(element) {
                    element.classList.remove('is--active');
                });

                // Add 'is--active' class to the currently active element
                var activeElement = document.querySelector(`[data-glide-graph="${currentIndex}"]`);
                if (activeElement) {
                    activeElement.classList.add('is--active');
                }
            });

            glideInstance.mount();

        });
    }

    let slider = $('.slider');
    if (slider.length > 0) {
        slider.each(function(index, slider) {
            var glideInstance = new Glide(slider, {
                type: 'slider',
                gap: 0,
                perView: 1,
                autoplay: 8000,
                rewindDuration: 200
            });

            let $next = $(slider).find('[data-glide-next]');
            let $prev = $(slider).find('[data-glide-prev]');

            $next.on('click', function(event) {
                event.preventDefault();
                glideInstance.go('>');
            });

            $prev.on('click', function(event) {
                event.preventDefault();
                glideInstance.go('<');
            });

            glideInstance.mount();
        });
    }

/**
 *
 * Product detail accordion
 *
 */
let $accordion = $('[data-accordion-item]');
$.each($accordion, function (index, item) {
  // Add some unique Id's to each accordion
  let itemId = Math.random().toString(36).substr(2, 9);
  $(item).find('[data-accordion-title]').attr('data-accordion-title', itemId);
  $(item).find('[data-accordion-content]').attr('data-accordion-content', itemId);

  // Capture the original text in the [data-accordion-title-closed-target]
  $(item).each(function() {
    let $accordionTitle = $(this).find('[data-accordion-title]');
    let $accordionTitleClosedTarget = $accordionTitle.find('[data-accordion-title-closed-target]');
    if ($accordionTitleClosedTarget.length) {
      $accordionTitle.data('original-title', $accordionTitleClosedTarget.text());
    }
  });

  // Setup an event handler
  $(item).on('click', function (e) {
    // Check if the click is inside [data-accordion-content]
    if ($(e.target).closest('[data-accordion-content]').length) {
      e.stopPropagation();
      return;
    }

    // Get the requested accordion title
    let $accordionItem = $(this).find('[data-accordion-title]');

    // Get the accordion id
    let accordionId = $accordionItem.data('accordion-title');

    // Check if the clicked accordion item is already active
    if ($accordionItem.hasClass('is-active')) {
      // Hide the current accordion content
      $('[data-accordion-content="' + accordionId + '"]').addClass('hidden is--hidden');
      $accordionItem.removeClass('is-active');

      // Set the text back to the original in the [data-accordion-title-closed-target]
      let $accordionTitleClosedTarget = $accordionItem.find('[data-accordion-title-closed-target]');
      if ($accordionTitleClosedTarget.length) {
        let originalTitle = $accordionItem.data('original-title');
        $accordionTitleClosedTarget.text(originalTitle);
      }
    } else {
      // Hide other accordion content
      $('[data-accordion-content]').addClass('hidden is--hidden');
      $('[data-accordion-title]').removeClass('is-active');

      // Show the requested accordion
      $('[data-accordion-content="' + accordionId + '"]').removeClass('hidden is--hidden');
      $accordionItem.addClass('is-active');

      // Check if [data-accordion-title] has any value in data-accordion-title-closed
      let closedText = $accordionItem.data('accordion-title-closed');
      let $accordionTitleClosedTarget = $accordionItem.find('[data-accordion-title-closed-target]');
      if (closedText && $accordionTitleClosedTarget.length) {
        $accordionTitleClosedTarget.text(closedText);
      }
    }
  });
});

    $(document).on('click', '[data-video-lightbox]', lity);

    // Bio toggle
    let $bio = $('[data-bio-toggle]')
    $bio.on('click', function (e) {

        $(window).off('scroll')

        e.preventDefault()

        // Elements
        let $container = $('[data-bio-container]')
        let $bio = $(this).find('[data-bio]')
        let id = $bio.data('bio')
        let $bioImg = $('[data-bio-img="' + id + '"]')
        let $bioImgAlt = $('[data-bio-img-alt="' + id + '"]')
        let $bioFooter = $('[data-bio-footer="' + id + '"]')
        let name = $(this).data('name')

        // States
        $container.toggleClass('invisible opacity-100')
        $('body').toggleClass('overflow-hidden')
        $('[data-contact-callout]').toggleClass('opacity-0 invisible')

        if ($(window).width() > 480) {
            $('[data-bio-modal]').height($(window).height())
            $('[data-bio-modal] .layout-content').height($(window).height() - $('[data-meet-title]').parent().parent().height() * 1.4)
        } else {
            $('[data-bio-modal]').height($(window).height() / 1.2)
        }

        // Content
        let imgHtml = $bioImg.html()
        if ($bioImgAlt.length > 0) {
            imgHtml = $bioImgAlt.html()
        }
        $('[data-bio-modal] picture').empty().html(imgHtml)
        $('[data-bio-modal] article').empty().html($bio.html())
        $('[data-bio-modal] footer').empty().html($bioFooter.html())
        $('[data-meet-title]').empty().html(name)

        // Scroll to top of area
        $('html, body').animate({
            scrollTop: $('[data-block="team-profiles"]').offset().top
        }, 200);

        $('.header-container').parent().removeClass('is--active');
        $('.header-container').removeClass('is--active');

        if ($container.hasClass('invisible')) {
            scrolling = false
            setupHeaderScroll()
        }

    })

// Function to handle toggling content, titles, and icons
document.querySelectorAll('[data-content-toggle]').forEach(function(toggleLink) {
    toggleLink.addEventListener('click', function(event) {
        event.preventDefault(); // Prevent default behavior

        // Get the unique key from the toggle link
        const key = this.getAttribute('data-content-toggle');

        // Find the target content with the matching unique key
        const target = document.querySelector(`[data-content-toggle-target="${key}"]`);

        // Find title and icon elements
        const titleTarget = this.querySelector('[data-content-toggle-title-target]');  // Use the correct selector for the title span
        const iconClosedTarget = this.querySelectorAll('[data-content-toggle-icon-closed-target]');
        const iconOpenTarget = this.querySelectorAll('[data-content-toggle-icon-open-target]');

        // Get the open/closed title attributes
        const titleClosed = this.getAttribute('data-content-toggle-title-closed');
        const titleOpen = this.getAttribute('data-content-toggle-title-open');

        if (target) {
            // Toggle the `is--hidden` class on the target content
            if (target.classList.contains('is--hidden')) {
                target.classList.remove('is--hidden'); // Show the content by removing the class
                if (titleTarget) titleTarget.textContent = titleOpen; // Update the title to "open" state

                // Switch icons - hide closed icon, show open icon
                iconClosedTarget.forEach(icon => icon.classList.add('hidden'));
                iconOpenTarget.forEach(icon => icon.classList.remove('hidden'));
            } else {
                target.classList.add('is--hidden'); // Hide the content by adding the class
                if (titleTarget) titleTarget.textContent = titleClosed; // Update the title to "closed" state

                // Switch icons - show closed icon, hide open icon
                iconClosedTarget.forEach(icon => icon.classList.remove('hidden'));
                iconOpenTarget.forEach(icon => icon.classList.add('hidden'));
            }
        }
    });
});


})

function openNewsletter() {

    var newsletterPreference = Cookies.get('newsletter_preference')

    if (typeof newsletterPreference === 'undefined') {
        Cookies.set('newsletter_preference', 'opened', { expires: 1 })
    } else if (newsletterPreference === 'dismiss' || newsletterPreference === 'opened' || newsletterPreference === 'subscribed') {
        return;
    }

    $('[data-newsletter-form]').addClass('opacity-100 visible').removeClass('invisible')
    $('body').addClass('overflow-hidden')

    $("[data-newsletter-dismiss]").on('click', function (e) {
        e.preventDefault()
        Cookies.set('newsletter_preference', 'dismiss')
        $('[data-newsletter-form]').removeClass('opacity-100 visible').addClass('invisible opacity-0')
        $('body').removeClass('overflow-hidden')
    })

}

$("[data-newsletter-open]").on('click', function (e) {

    e.preventDefault();

    $('[data-newsletter-form]').addClass('opacity-100 visible').removeClass('invisible')
    $('body').addClass('overflow-hidden')


    $("[data-newsletter-dismiss]").on('click', function (e) {
        e.preventDefault()
        Cookies.set('newsletter_preference', 'dismiss')
        $('[data-newsletter-form]').removeClass('opacity-100 visible').addClass('invisible opacity-0')
        $('body').removeClass('overflow-hidden')
    })

})

// setTimeout(openNewsletter, 20000);

// if ($('body.page-id-1233').length > 0) {
//     Cookies.remove('newsletter_preference')
//     Cookies.set('newsletter_preference', 'subscribed', { expires: 90 })
// }

// if (config.env === 'production') {
//     cookieConsent.init();
// }